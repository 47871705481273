* {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  background-color: #f6f7f9;
}

h1,
h2,
h3 {
  font-weight: 700;
}

p {
  font-weight: 400;
}

small {
  font-weight: 300;
}

.App-header {
  background-color: #0059db;
  height: 60px;
  padding: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-title {
  font-size: 30px;
  font-weight: 600;
}

.App-header .App-title {
  margin: 0;
}

.App-header .App-title a {
  color: #fff;
  text-decoration: none;
}

.right_search_bar {
  display: flex;
  justify-content: end;
}

.lesson_with_buttons {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}

.lesson_with_buttons .lesson_name h4 {
  text-align: center;
  margin-bottom: 0;
  font-size: 22px;
}

.lesson_with_buttons .next_btn {
  justify-content: end;
  display: flex;
}

.prev_next_btn_bottom .next_btn {
  display: flex;
  justify-content: end;
}

.form-control {
  background-color: #fff;
  border: 1px solid #D5D9E2;
  height: 50px;
  color: #3A4252 !important;
  font-size: 17px;
  padding: 14px 30px 14px 17px;
  width: 100%;
  transition: all ease 0.5s;
  border-radius: 7px;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-clip: padding-box;
}

.right_search_bar button {
  font-size: 17px;
}

.top_search_bar {
  margin: 40px 0;
}

.form-group {
  margin-bottom: 0;
}

.prev_btn button,
.next_btn button {
  font-size: 17px;
}

.prev_next_btn_bottom {
  margin-bottom: 30px;
}

.form-check .form-check-input {
  border: 2px solid #64748B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: #f6f7f9;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: #f6f7f9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check .form-check-input:focus {
  box-shadow: none;
  border-color: #605DFF;
}

.form-check .form-check-input:checked[type=checkbox] {
  border-color: #605DFF;
}

.table_content table tbody tr:last-child {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.table_content table thead tr td button img {
  filter: invert(1);
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.table_content table thead tr td button {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.table_content table tbody tr {
  width: 100%;
}

.table_content table tbody tr td {
  width: 97%;
}

.table_content table tbody tr td:last-child {
  width: 3%;
  justify-content: end;
  display: flex;
  margin-left: auto;
}

.table_content table tbody tr:hover {
  cursor: pointer;
}

.verse-container {
  width: 100%;
  justify-content: space-between;
}

.full-width {
  width: 100%;
  text-align: justify;
}

.half-width {
  width: 49%;
}

.search_copy_btn img {
  height: 15px;
  width: auto;
  filter: invert(1);
}

.search_copy_btn {
  width: auto;
  height: auto;
}

.table_search_content table tbody tr td button.bg-danger {
  font-size: 17px;
}

.table_search_content table tbody tr {
  width: 100%;
}

.table_search_content table tbody tr td .default_language {
  align-items: baseline;
}

.table_search_content .card .card-body h4 {
  font-size: 22px;
}

.search_heilight {
  font-size: 22px;
}

footer {
  background-color: #0059db;
  padding: 10px;
}

footer .coppyright p {
  color: #fff;
  margin-bottom: 0;
}

footer .coppyright p a {
  color: #fff;
  text-decoration: none;
}

footer .coppyright.right-side {
  justify-content: end;
  display: flex;
}

.fixed-actions .fixed-actions-container .font-size-increase button,
.fixed-actions .fixed-actions-container .font-size-decrease button,
.fixed-actions .fixed-actions-container .nt-books-btn button,
.fixed-actions .fixed-actions-container .ot-books-btn button,
.fixed-actions .fixed-actions-container .chs-books-btn button {
  font-size: 17px;
}

.fixed-actions .fixed-actions-container .font-size-increase button,
.fixed-actions .fixed-actions-container .font-size-decrease button {
  margin-left: -2.7em;
}

.fixed-actions .fixed-actions-container .nt-books-btn button,
.fixed-actions .fixed-actions-container .ot-books-btn button,
.fixed-actions .fixed-actions-container .chs-books-btn button {
  margin-right: -4em;
}

.fixed-actions {
  position: fixed;
  top: 45%;
  width: 100%;
  left: 0;
  z-index: 0;
}

.fixed-actions .custom-font-size {
  display: flex;
  justify-content: start;
  margin-right: auto;
}

.fixed-actions .fixed-books-chapters {
  display: flex;
  justify-content: end;
  margin-left: auto;
}

.fixed-actions .fixed-actions-container {
  margin: 0 auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.fixed-actions .fixed-actions-container .font-size-increase,
.fixed-actions .fixed-actions-container .font-size-decrease,
.fixed-actions .fixed-actions-container .nt-books-btn,
.fixed-actions .fixed-actions-container .ot-books-btn,
.fixed-actions .fixed-actions-container .chs-books-btn {
  display: flex;
  margin-bottom: 10px;
}

.nt-books-container,
.ot-books-container,
.chs-books-container {
  position: fixed;
  top: 35%;
  left: 44%;
  background-color: #ffffff;
  padding: 10px;
  display: block;
  z-index: 10;
  width: 40%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.nt-books-container ul,
.ot-books-container ul,
.chs-books-container ul {
  list-style: none;
  display: inline;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 0;
}

.nt-books-container li,
.ot-books-container li,
.chs-books-container li {
  cursor: pointer;
  float: left;
  margin-bottom: 6px;
  font-size: 17px;
  margin-right: 6px;
}

.nt-books-container li:hover,
.ot-books-container li:hover,
.chs-books-container li:hover {
  background-color: #007bff;
  color: white;
}

.table_content.row {
  z-index: 1;
  position: relative;
}

@media only screen and (min-width:320px) and (max-width:768px) {

  .verse-container .default_language.half-width.d-flex,
  .verse-container .second_language.half-width.d-flex
  {
    display: block !important;
    width: 100%;
  }
  .verse-container .default_language.half-width.d-flex
  {
    margin-bottom: 10px;
  }
  .verse-container
  {
    display: block !important;
  }
  .table_content table tbody tr td:last-child {
    display: table-cell;
}

  .top_search_bar.row .col-sm-12.col {
    width: 100%;
    flex: auto;
  }

  .left_search_bar .col-auto {
    flex: 0 0 auto;
    width: -webkit-fill-available;
  }

  .left_search_bar .col-auto.col .form-group {
    margin-bottom: 10px;
  }

  .left_search_bar .col-auto.col .form-group .form-control {
    font-size: 15px;
  }

  .lesson_with_buttons .res-1 {
    order: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex: auto;
  }

  .lesson_with_buttons .res-2 {
    order: 2;
  }

  .lesson_with_buttons .res-3 {
    order: 3;
  }

  .prev_btn button,
  .next_btn button {
    font-size: 13px;
  }

  .fixed-actions {
    position: fixed;
    top: inherit;
    bottom: 14%;
  }

  .fixed-actions .fixed-actions-container .nt-books-btn button,
  .fixed-actions .fixed-actions-container .ot-books-btn button,
  .fixed-actions .fixed-actions-container .chs-books-btn button {
    margin-right: 0;
  }

  .fixed-actions {
    z-index: 1;
  }

  .fixed-actions .fixed-actions-container .font-size-increase button,
  .fixed-actions .fixed-actions-container .font-size-decrease button {
    margin-left: 0;
  }

  .fixed-actions .fixed-actions-container .font-size-increase,
  .fixed-actions .fixed-actions-container .font-size-decrease,
  .fixed-actions .fixed-actions-container .nt-books-btn,
  .fixed-actions .fixed-actions-container .ot-books-btn,
  .fixed-actions .fixed-actions-container .chs-books-btn {
    float: left;
    margin-right: 8px;
  }

  .nt-books-container,
  .ot-books-container,
  .chs-books-container {
    top: 25%;
    left: 5%;
    width: 90%;
  }

  .nt-books-container ul,
  .ot-books-container ul,
  .chs-books-container ul {
    height: 395px;
    overflow: auto;
  }

  .fixed-actions .fixed-books-chapters {
    margin-left: 0;
  }

  .fixed-actions .custom-font-size {
    display: none;
  }

  .fixed-actions .fixed-actions-container {
    justify-content: center;
  }

  .table_content table tbody tr td {
    width: 100%;
  }

  .table_content table tbody tr td:last-child {
    width: auto;
  }

  footer .container .row .col-lg-6.col-md-6.col {
    flex: auto;
  }

  footer .container .row .col-lg-6.col-md-6.col .coppyright {
    text-align: center;
  }
}

@media only screen and (min-width:769px) and (max-width:991px) {
  .top_search_bar .left_search_bar .row {
    justify-content: center;
  }

  .top_search_bar .left_search_bar .row .col-auto.col .form-group {
    margin-bottom: 12px;
  }

  .fixed-actions {
    position: fixed;
    top: inherit;
    bottom: 14%;
  }

  .fixed-actions .fixed-actions-container .nt-books-btn button,
  .fixed-actions .fixed-actions-container .ot-books-btn button,
  .fixed-actions .fixed-actions-container .chs-books-btn button {
    margin-right: 0;
  }

  .fixed-actions {
    z-index: 1;
  }

  .fixed-actions .fixed-actions-container .font-size-increase button,
  .fixed-actions .fixed-actions-container .font-size-decrease button {
    margin-left: 0;
  }

  .fixed-actions .fixed-actions-container .font-size-increase,
  .fixed-actions .fixed-actions-container .font-size-decrease,
  .fixed-actions .fixed-actions-container .nt-books-btn,
  .fixed-actions .fixed-actions-container .ot-books-btn,
  .fixed-actions .fixed-actions-container .chs-books-btn {
    float: left;
    margin-right: 8px;
  }

  .nt-books-container,
  .ot-books-container,
  .chs-books-container {
    top: 25%;
    left: 5%;
    width: 90%;
  }

  .nt-books-container ul,
  .ot-books-container ul,
  .chs-books-container ul {
    height: 300px;
    overflow: auto;
  }

  .fixed-actions .fixed-books-chapters {
    margin-left: 0;
  }

  .fixed-actions .custom-font-size {
    display: none;
  }

  .fixed-actions .fixed-actions-container {
    justify-content: center;
  }

  .table_content table tbody tr td {
    width: 100%;
  }

  .table_content table tbody tr td:last-child {
    width: auto;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .top_search_bar.row .col-sm-12.col {
    width: 100%;
    flex: auto;
  }

  .top_search_bar.row .col-sm-12.col .left_search_bar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .table_content table tbody tr td {
    width: 100%;
  }

  .table_content table tbody tr td:last-child {
    width: auto;
  }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
  .left_search_bar .col-auto.col .form-group .form-control {
    font-size: 15px;
  }
}

.custom-tooltip {
  position: relative;
  cursor: pointer;
}

.custom-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  right: 110%; /* Adjust for right side tooltip */
  top: 50%;
  transform: translateY(-50%);
  background-color: #333; /* Dark background */
  color: #fff; /* White text */
  padding: 8px 12px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  font-size: 0.875rem;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.custom-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.custom-tooltip::before {
  content: '';
  position: absolute;
  right: 100%; /* Adjust for right side tooltip */
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #333 transparent transparent; /* Triangle pointing to button */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.custom-tooltip:hover::before {
  opacity: 1;
  visibility: visible;
}

#popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 500px;
  height: 500px;
  overflow: auto;
}

.popup-content {
  position: relative;
}

#close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  background: #efefef;
  padding: 5px 5px 5px 12px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 27px;
}

.logo-img {
  height: 70px;
  width: auto;
  margin-right: 20px;
}
.second_language
{
  display: none;
}
.footnote-sym {
  padding-top: 8px;
  padding-bottom: 0;
}
@media only screen and (min-width:320px) and (max-width:768px) {
  #popup {
    width: 90%;
    height: 500px;
    overflow: auto;
  }

  .logo-img {
    height: 50px;
    width: auto;
    margin-right: 15px;
  }
  .second_language
  {
    display: flex;
  }
  .res-first-tr
  {
    margin-bottom: 0 !important;
    border-bottom: none !important;
  }
  .res-second-tr .second_language
  {
    padding-bottom: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    width: 100%;
  }
  sup
  {
    height: 20px;
    width: 20px;
  }
  .table_content.right-side{
    display: none;
  }
  .content_col_right
  {
    order: 1;
  }
  .content_col_left
  {
    order: 2;
  }
}
