body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.loading-icon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}